import { getReference } from '@atc/bonnet-reference';

// eslint-disable-next-line import/prefer-default-export
export const getCrawlpathDataMaps = async (makeCode = '') => {
    const { payload: makeCodes = [] } = await getReference('makeCode');
    const { payload: vehicleStyleCodes = [] } = await getReference('vehicleStyleCode');
    const { payload: modelCodes = [] } = makeCode ? await getReference('modelCode', { makeCode }) : {};

    const makesMap = {};
    const vehicleStylesMap = {};
    const modelsMap = {};

    makeCodes.map(({ code, name }) => makesMap[code] = name);

    vehicleStyleCodes.map(({ code, shortName }) => {
        // pluralize body style names
        shortName = shortName.split(' / ').map((style) => style + 's').join(' / ');
        return vehicleStylesMap[code] = shortName;
    });

    modelCodes.map(({ code, name }) => modelsMap[code] = name);

    return { makesMap, vehicleStylesMap, modelsMap };
};
